<template>
  <div class="more-filters-button d-flex justify-content-between">
    <div class="d-flex col-9">
      <b-dropdown block no-caret class="dropdown-filters-category dropdown-filter-content-description" variant="outline-gray-light" menu-class="left-init-side" ref="dropdown_search">
        <template #button-content>
          <div class="d-flex color-gray-light h-100">
            <span class="d-block span-filter-content-description">
            {{$t(`search.${where}`)}}
            <feather-icon icon="ChevronDownIcon" class="chevron-dropdown-search-by"></feather-icon>
            </span>
          </div>
        </template>
        <b-dropdown-header class="p-0">
          <div class="content-description-header">
            <div class="category-item-dropdown position-relative" @click="changeDropSearch('anywhere')">{{$t('search.anywhere')}}</div>
            <div class="category-item-dropdown position-relative" @click="changeDropSearch('content')">{{$t('search.content')}}</div>
            <div class="category-item-dropdown position-relative" @click="changeDropSearch('description')">{{$t('search.description')}}</div>
          </div>
        </b-dropdown-header>
      </b-dropdown>
      <b-form-input 
          class="input-search-by-influencer" 
          :placeholder="$t('search.placerholder_search_content')"
          v-model="keywords"  
      ></b-form-input>
    </div>
    <span 
    v-if="keywords.length !== 0"
    class="d-block span-add-keyword"
    @click="addKeywordFilter"
    >
    {{$t('search.add_keyword')}}
    </span>
  </div>
</template>

<script>
import {
  BDropdown, 
  BDropdownHeader, 
  BFormInput,
} from 'bootstrap-vue';

export default {
  components: {
    BDropdown,
    BDropdownHeader,
    BFormInput
  },
  data() {
    return {
      keywords: this.keywords_dad,
      where: this.where_dad
    }
  },
  props: {
    keywords_dad: {
      type: String,
      default: ''
    },
    where_dad: {
      type: String,
      default: 'anywhere'
    }
  },
  methods: {
    changeDropSearch(value) {
      this.where = value;
      this.$refs.dropdown_search.hide();
    },
    addKeywordFilter() {
      const new_models = [{name: 'keywords', value: this.keywords}];
      this.$emit('add_keyword_filter', new_models, this.keywords, this.where);
    },
  }
}
</script>